[data-theme="light"] {
  display: block;
}

[data-theme="dark"] {
  display: none;
}

.dark [data-theme="light"] {
  display: none;
}

.dark [data-theme="dark"] {
  display: block;
}

[data-rehype-pretty-code-fragment] {
  @apply relative;
}

[data-rehype-pretty-code-fragment] code {
  @apply grid min-w-full break-words rounded-none border-0 bg-transparent p-0;
  counter-reset: line;
  box-decoration-break: clone;
  line-height: 1.7;
}

[data-rehype-pretty-code-fragment] .line {
  @apply inline-block min-h-[1rem] w-full px-4 py-0.5;
}

[data-rehype-pretty-code-fragment] [data-line-numbers] .line {
  @apply px-2;
}

[data-rehype-pretty-code-fragment]
  [data-line-numbers]
  > .line--highlighted::before {
  @apply text-slate-600;
}

[data-rehype-pretty-code-fragment] [data-line-numbers] > .line::before {
  @apply text-xs text-slate-400;
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  width: 1rem;
  margin-right: 2rem;
  text-align: right;
}

[data-rehype-pretty-code-fragment]
  code[data-line-numbers-max-digits="2"]
  > .line::before {
  width: 2rem;
}

[data-rehype-pretty-code-fragment]
  code[data-line-numbers-max-digits="3"]
  > .line::before {
  width: 3rem;
}

[data-rehype-pretty-code-fragment] .line--highlighted {
  @apply bg-slate-700/50;
}

[data-rehype-pretty-code-fragment] .line-highlighted span {
  @apply relative;
}

[data-rehype-pretty-code-fragment] .word--highlighted {
  @apply rounded-md border-slate-700/70 bg-slate-700/50 p-1;
}

.dark [data-rehype-pretty-code-fragment] .word--highlighted {
  @apply bg-slate-900;
}

[data-rehype-pretty-code-title] {
  @apply mt-2 px-4 pt-6 text-sm font-medium;
}

[data-rehype-pretty-code-title] + pre {
  @apply mt-2;
}

article > .steps:first-child > h3:first-child {
  @apply mt-0;
}
